<!-- 已废弃页面 -->
<!--
 * @Author: Chen-
 * @Description: 信用卡详情
 * @Date: 2020-11-18 20:07:20
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-25 16:03:17
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/creditCard/Creditcardinfo.vue
-->
<template>
  <div class="content" :style="{backgroundImage:'url(' + leadPageUrl + ')'}">
    <img src="" alt="">
    <div class="creditinfo">
      <div v-if="isRewardRules" class="creditrule">
        <div>奖励规则</div>
        <div @click="upx">{{ rewardText }}奖励规则
          <van-icon :name="kess" />
        </div>
      </div>
      <div v-if="isRewardRules" :class="key === true?'credittxt wrapper':'credittxt'">
        <div class="tx_item">
          <p class="tx_title">支持城市</p>
          <p class="tx_x">{{ supportCity }}</p>
        </div>
        <div class="tx_item">
          <p class="tx_title">{{ settlementRules }}</p>
          <!--                    <p class="tx_x">激活成功，查询进度，次日结算。</p>-->
        </div>
      </div>
      <div class="creditbtn">
        <div @click="oneself">{{ cardAmount }}</div>
        <div @click="poster">{{ shareAmount }}</div>
      </div>
    </div>
    <!--      确认申请的弹窗-->
    <van-dialog
      v-model="show"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="dia_box">
        <div class="dai_title"><span>重要信息</span>
          <div class="cods" @click="offshow">
            <van-icon name="cross" />
          </div>
        </div>
        <div class="dia_tips">请您确保后续填写的申请信息<b>与您注册的平台信息保持一致</b>，否则无法获得订单奖励金结算。</div>
        <ul class="dia_list">
          <li><span>姓名：</span><span>{{ form.name | name_status }}</span></li>
          <li><span>身份证号：</span><span>{{ form.idEntityNo | idEntityNo_status }}</span></li>
          <li><span>手机号：</span><span>{{ form.mobile | Phone_status }}</span></li>
        </ul>
        <div class="dia_btn">
          <div @click="ModifyInfo">修改申请信息</div>
          <div @click="ConfirmApply">确认申请</div>
        </div>
      </div>
    </van-dialog>
    <!--      确认修改的弹窗  -->
    <van-dialog
      v-model="Modify"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="dia_box">
        <div class="dai_title"><span>申请信息修改</span>
          <div class="cods" @click="offshowModify">
            <van-icon name="cross" />
          </div>
        </div>
        <div class="dia_tips">请您确保后续填写的申请信息<b>与您注册的平台信息保持一致</b>，否则无法获得订单奖励金结算。</div>
        <div class="titlePrompt">
          <div v-if="isShow" class="information">
            <p class="informationTitle">申请人姓名：</p>
            <input v-model="form.name" class="informationInp" type="text">
          </div>
          <div v-if="isShow" class="van-hairline--bottom" />
          <div v-if="isShow" class="information">
            <p class="informationTitle">身份证 ：</p>
            <input v-model="form.idEntityNo" class="informationInp" type="text">
          </div>
          <div v-if="isShow" class="van-hairline--bottom" />
          <div class="information">
            <p class="informationTitle">手机号码：</p>
            <input v-model="form.mobile" class="informationInp" type="number">
          </div>
          <div class="van-hairline--bottom" />
          <div class="information">
            <p class="informationTitle">短信验证码：</p>
            <input v-model="form.code" class="informationInp" style="width: 45%;" type="number">
            <button class="codeTextStyle" :style="{color:codeColor}" @click="codeTextClick">{{ codeText }}</button>
          </div>
          <div class="van-hairline--bottom" />
        </div>
        <div class="dia_btn">
          <div @click="cancelModify">取消</div>
          <div @click="ConfirmModify">确认修改</div>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
// const ALL_SECOND = 60
import Vue from 'vue'
import { Dialog, Field, Form, Icon, Toast } from 'vant'

Vue.use(Toast).use(Icon).use(Dialog).use(Form).use(Field)
import {
  generatePosterForBank,
  getAppInfo,
  setSMSCode,
  ModifyAppInfo,
  createOrder
} from '@/services/comeOn.js'
import { formatPhone, hideRangeInString } from '@/utils/common/utils'

export default {
  filters: {
    Phone_status(v) {
      return formatPhone(v)
    },
    idEntityNo_status(v) {
      return hideRangeInString(v, 6, 4)
    },
    name_status(v) {
      return hideRangeInString(v, 0, 1)
    }
  },
  data() {
    return {
      Sub: false,
      key: true,
      show: false, // 确认申请的弹窗
      Modify: false, // 修改信息的弹窗
      isRewardRules: false, // 无数据不显示奖励规则
      kess: 'arrow-up', // arrow-up
      defaultBgcUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-18/15/yuelvhuiAtB3d720tX1605685525.png',
      leadPageUrl: '',
      form: {
        name: '',
        idEntityNo: '',
        mobile: ''
      },
      isShow: true,
      codeColor: '#1F5DEE',
      sourceSign: '2095CE15', // 渠道编码
      thirdSign: '', // 三方字符串
      sharer: '', // 分享者id
      codeText: '获取验证码',
      codeNumber: 60,
      supportCity: '', // 规则中支持的城市
      settlementRules: '', // 奖励规则
      rewardText: '展开',
      gateWayId: ''
    }
  },
  created() {
    const {
      // leadPageUrl = this.defaultBgcUrl,
      bankCardId = '',
      typeId = null,
      bankId = '',
      sourceSign = '2095CE15',
      supportCity = '暂无数据',
      settlementRules = '暂无数据',
      cardAmount,
      shareAmount,
      title,
      thirdSign,
      sharer,
      merCardId,
      gateWayId
    } = this.$route.query
    // this.leadPageUrl = this.$route.query.leadPageUrl ? this.$route.query.leadPageUrl : this.defaultBgcUrl;//背景图
    this.bankCardId = bankCardId // 银行标识2
    this.bankId = bankId // 银行id
    this.gateWayId = gateWayId ? Number(gateWayId) : ''// gateWayId
    if (this.gateWayId === 1) {
      this.isShow = false
    }
    // 渠道编码
    this.sourceSign = sourceSign
    // 三方标识
    this.thirdSign = thirdSign
    // 分享者id
    this.sharer = sharer
    // typeId
    this.typeId = typeId ? Number(typeId) : ''
    // 奖励中支持的城市
    this.supportCity = supportCity
    // 奖励规则
    this.settlementRules = settlementRules
    // 办卡返
    this.cardAmount = cardAmount
    // 分享返
    this.shareAmount = shareAmount
    // title
    this.title = title
    this.isRewardRules = !(this.supportCity === '暂无数据' && this.settlementRules === '暂无数据')
    this.merCardId = merCardId
    // 通过分享的接口取背景图
    this.getBgc()
  },
  mounted() {
    this.getInfo()// get用户信息
  },
  methods: {
    validator(val) {
      return /1\d{10}/.test(val)
    },
    getBgc() {
      Toast.loading()
      generatePosterForBank({
        mid: window.localStorage.getItem('uid'),
        bankId: this.bankId,
        type: this.typeId,
        merCardId: this.merCardId

      }).then(res => {
        Toast.clear()
        if (Number(res.code) === 200) {
          this.leadPageUrl = res.data.noQrImg
        } else {
          Toast(res.msg)
        }
      })
    },
    // 分享
    poster() {
      this.$router.push({
        path: '/infoposter',
        query: {
          mid: window.localStorage.getItem('uid'),
          bankId: this.bankId,
          typeId: this.typeId,
          merCardId: this.merCardId,
          title: this.title
        }
      })
    },
    // get用户信息
    getInfo() {
      getAppInfo({
        mid: window.localStorage.getItem('uid')
      }).then(res => {
        if (Number(res.code) === 200) {
          this.form.name = res.data.name
          this.form.mobile = res.data.mobile
          this.form.idEntityNo = res.data.idEntityNo
        }
      })
    },
    // 确认申请提交
    ConfirmApply() {
      const that = this
      this.form.mid = window.localStorage.getItem('uid')
      // 创建订单并跳转到3方页面
      if (that.gateWayId === 1) {
        that.form.name = '京东卡'
        that.form.idEntityNo = Math.floor(Math.random() * 999999999999999999)// 18位随机数字
      }
      const createOrderData = {
        merMobile: that.form.mobile, // 手机号
        merIdentityNo: that.form.idEntityNo, // 身份证（银行列表gateWayId值为1时，这里传18位不可重复的随机数）
        merName: that.form.name, // 姓名（银行列表gateWayId值为1时，这里传固定的名称为'京东卡'）
        bankCardId: that.bankCardId, // 银行标识2（银行列表中的merCardId字段）
        bankId: that.bankId, // 银行id
        gateWayId: that.gateWayId, // 如果返回1页面不显示填写姓名和身份证
        thirdSign: that.thirdSign, // 三方标识
        sourceSign: that.sourceSign, // 渠道编码
        mid: window.localStorage.getItem('uid'), // 用户id（如果渠道编码是邻居团的则不传）
        sharer: that.sharer, // 分享者id
        typeId: that.typeId,
        bid: that.bankId
      }
      createOrder(createOrderData).then(res => {
        if (Number(res.code) === 200) {
          window.location.replace(res.data.url)
        } else {
          Toast(res.msg)
        }
      })
    },
    // 修改信息
    ModifyInfo() {
      this.show = false// 关闭重要信息页
      this.Modify = true// 打开修改信息页
    },
    // 确认修改
    ConfirmModify() {
      if (this.form.name === '') {
        Toast('请输入申请人姓名')
        return
      } else if (this.form.idEntityNo === '') {
        Toast('请输入身份证信息')
        return
      } else if (this.form.mobile === '') {
        Toast('请输入手机号')
        return
      } else if (this.form.code === '') {
        Toast('请获取手机验证码并填写')
        return
      } else if (this.checked === false) {
        Toast('请阅读服务协议并选中')
        return
      }
      this.form.mid = window.localStorage.getItem('uid')
      ModifyAppInfo(this.form).then(res => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
          // 修改成功关闭修改页面，打开信息确认页面
          this.Modify = false
          this.show = true
          // 重新获取最新信息
          this.getInfo()
        } else {
          Toast(res.msg)
        }
      })
    },
    // get验证码
    codeTextClick() {
      const that = this
      if (this.form.mobile === '') {
        Toast('请填写手机号')
        return false
      } else {
        setSMSCode({
          mobile: that.form.mobile
        }).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            that.disabled = true// 禁用按钮
            that.codeColor = '#ccc'
            that.codeText = that.codeNumber + 's'
            const Interval = setInterval(function() {
              (that.codeNumber)--
              that.codeText = that.codeNumber + 's'
              if (that.codeNumber === 0) {
                clearInterval(Interval)
                that.codeText = '获取验证码'
                that.disabled = false// 启用按钮
                that.codeColor = '#1F5DEE'
                that.codeNumber = 60
              }
            }, 1000)
          } else {
            Toast(res.msg)
          }
        })
      }
    },
    // 展开奖励规则
    upx() {
      if (this.key) {
        this.kess = 'arrow-down'
        this.key = false
        this.rewardText = '收起'
      } else {
        this.kess = 'arrow-up'
        this.key = true
        this.rewardText = '展开'
      }
    },
    // 办卡
    oneself() {
      this.$router.push({
        path: '/infoCollection',
        query: {
          leadPageUrl: this.leadPageUrl,
          bankCardId: this.bankCardId,
          bankId: this.bankId,
          gateWayId: this.gateWayId,
          typeId: this.typeId
        }
      })
    },
    // 确认信息关闭
    offshow() {
      this.show = false
    },
    offshowModify() {
      this.Modify = false
    },
    // 取消修改
    cancelModify() {
      this.Modify = false
      this.show = true
    }
  }
}
</script>

<style lang="less" scoped>
html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  /*position: fixed;*/
  margin: 0;
  padding: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
  min-height: 100vh;

  .creditinfo {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 127px;
    background-color: #FFFFFF;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    padding: 10px 20px;

    .creditrule {
      display: flex;
      align-items: center;

      & > div:nth-child(1) {
        flex: 12;
        text-align: left;
        font-size: 15px;
      }

      & > div:nth-child(2) {
        flex: 12;
        text-align: right;
        color: #666666;
        font-size: 13px;
      }
    }

    .credittxt {
      text-align: left;
      margin: 20px 0;
      color: #333333;

      .tx_item {
        margin-top: 10px;

        .tx_title {
          font-size: 14px;
        }

        .tx_x {
          font-size: 13px;
          margin-top: 4px;
          line-height: 20px;
        }
      }
    }

    .credittxt.wrapper {
      height: 0;
      display: none;
      transition: height 0.6s ease-in-out;
    }

    .creditbtn {
      display: flex;
      margin: 20px 0;

      div {
        flex: 11;
        font-size: 14px;
        font-weight: 500;
        border-radius: 7px;
        padding: 15px;
      }

      & > div:nth-child(1) {
        border: 1px solid #E0AA81;
        color: #DDA67C;
        font-size: 14px;
        margin-right: 10px;
      }

      & > div:nth-child(2) {
        color: #fff;
        font-size: 14px;
        background-image: linear-gradient(to right, #FED3B2, #E0AA81);
      }
    }
  }

  .dia_box {
    padding: 10px;

    .codeTextStyle {
      color: #1F5DEE;
      font-size: 12px;
      line-height: 15px;
      background: none;
      border: none;
    }

    .dai_title {
      padding: 10px 0;
      font-size: 17px;
      color: #333333;
      line-height: 16px;
      position: relative;

      .cods {
        position: absolute;
        right: 10px;
        top: 6px;
      }
    }

    .dia_tips {
      margin: 10px 0;
      color: #333333;
      font-size: 14px;
      line-height: 18px;
      text-align: left;

      & > b {
        color: #1F83EE;
      }
    }

    .dia_list {
      text-align: left;

      li {
        padding: 4px 30px;
        font-size: 13px;
        line-height: 18px;

        span {
          display: inline-block;
          width: 65px;
          text-align: left;
        }
      }
    }

    .dia_btn {
      display: flex;
      margin: 20px 0 10px;

      div {
        flex: 11;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        padding: 15px;
      }

      & > div:nth-child(1) {
        border: 1px solid #1F3AED;
        color: #1F5DEE;
        margin-right: 10px;
      }

      & > div:nth-child(2) {
        color: #fff;
        background-image: linear-gradient(to right, #1F83EE, #1F39ED);
      }
    }
  }

  .titlePrompt {
    font-size: 15px;
    line-height: 32px;
    margin-top: 18px;
    width: 100%;
  }

  .information {
    display: flex;
    height: 32px;
    line-height: 32px;
  }

  .informationTitle {
    width: 30%;
    -webkit-text-size-adjust: none;
    font-size: 12px;
    color: #666;
    text-align: left;
  }

  .informationInp {
    background: none;
    outline: none;
    border: none;
    font-size: 12px;
    color: #000;
  }

  .informationInp::-webkit-input-placeholder { /* WebKit browsers */
    color: #CBCBCB;
    font-size: 12px;
  }

  .TermsChoiceWrap {
    height: 20px;
    padding: 10px 20px;
  }

  .TermsChoiceRadio {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .TermsChoiceText1 {
    display: inline-block;
    color: #BCBCBD;
    line-height: 20px;
  }

  .TermsChoiceText2 {
    display: inline-block;
    color: #88BBDF;
  }

  .codeTextStyle {
    background: none;
    border: none;
    width: 25%;
    padding: 0;
    /*color: #1F5DEE;*/
    font-size: 12px;
    text-align: center;
  }
}
</style>
